/* istanbul ignore file */

import { MenuWrapper, useRequestInit } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import OpenDossierLogContext from "../open-dossier-log/open-dossier-log-context";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { useMenuInfoData } from "../shared/hooks/use-menu-info-data";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { AppDataContext } from "./appdata-context";
import { menuConfig } from "./menu-config";
import { useHistory } from "react-router-dom";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
};

const NavigationList = ({ collapsed, collapse }: NavigationListProps): ReactElement => {
  const { params } = useRequestInit<RouteParams>();
  const { reloadCount, setMenuInfo } = useContext(AppDataContext);
  const { data: menuInfo, loading } = useMenuInfoData(reloadCount);
  const history = useHistory();

  const config = useMemo(() => menuConfig(params), [params]);

  useEffect(() => {
    if (menuInfo) {
      setMenuInfo(menuInfo);
    }
  }, [setMenuInfo, menuInfo]);
  const logContext = useContext(OpenDossierLogContext);

  if (menuInfo && menuInfo.klantdossierId && params.vestiging && params.adviesdossier) {
    logContext.setAdviesDossierId(params.adviesdossier, menuInfo.klantdossierId, params.vestiging);
  }

  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={!!menuInfo}
      loading={loading}
      menuConfig={config}
      appName={"documentbeheer"}
      onClickCallback={/* istanbul ignore next */ () => history.push(`/vestiging/${params.vestiging}/zoeken`)}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default NavigationList;
