import React, { ReactElement, useContext, useState } from "react";
import {
  AdviseurMenu,
  AuthContext,
  FormFirstFocus,
  Icon,
  InvalidAdviesboxResultErrorPage,
  Notificaties2Menu,
  NotificatiesMenu,
  SupportMenu,
  SupportRemoteAssistanceModal,
  SupportTicketModalAjax,
  useFeature,
  useRequestInit,
  ZoekschermBody,
  ZoekschermHeader,
  ZoekschermHeaderColumn,
  ZoekschermHeading,
  ZoekschermItem,
  ZoekschermProductSelect,
  ZoekschermTopNavbar,
} from "adviesbox-shared";
import { Form } from "formik";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { ZoekschermState, zoekschermSchema } from "./infra/zoekscherm-schema";
import { Debug } from "../shared/components/formik/Debug";
import NewLogo from "../assets/new-brand-logo.svg";
import "./zoekscherm.scss";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(false);
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [
    showInvalidAdviesboxResultErrorPage,
    setShowInvalidAdviesboxResultErrorPage,
  ] = useState(false);

  const featureNotificaties2 = useFeature("FeatureNotificaties2");
  const { user } = useContext(AuthContext);
  const { params } = useRequestInit();

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  return (
    <FormFirstFocus>
      <Form>
        <ZoekschermHeader>
          <ZoekschermTopNavbar>
            <ZoekschermHeaderColumn alignRight={false}>
              <ZoekschermProductSelect currentApp="DOC" logo={NewLogo} />
            </ZoekschermHeaderColumn>
            <ZoekschermHeaderColumn alignRight={true}>
              <ZoekschermItem>
                <SupportMenu
                  showMeldingMaken={
                    /*istanbul ignore next*/ () => setShowMeldingMaken(true)
                  }
                  showRemoteAssistance={
                    /*istanbul ignore next*/ () => setShowRemoteAssistance(true)
                  }
                  classes={{
                    iconblack: classes.iconblack,
                    iconpadding: classes.iconpadding,
                  }}
                />
              </ZoekschermItem>
              <ZoekschermItem>
                {!featureNotificaties2 && (
                  <div data-testid="NotificatiesMenu">
                    <NotificatiesMenu />
                  </div>
                )}
                {featureNotificaties2 && (
                  <div data-testid="NewNotificatiesMenu">
                    <Notificaties2Menu />
                  </div>
                )}
              </ZoekschermItem>
              <ZoekschermItem>
                <AdviseurMenu />
              </ZoekschermItem>
            </ZoekschermHeaderColumn>
            <ZoekschermHeading heading="Adviesbox Documenten" />
            <Zoeken />
          </ZoekschermTopNavbar>
        </ZoekschermHeader>
        <ZoekschermBody>
          <div className="container">
            <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
              <button
                type="button"
                onClick={toggleShowDossiers}
                className={`${classes.clickable} d-flex flex-row flex-wrap`}
                id="toggle-show-dossiers"
              >
                <h2 className={"m-0 align-self-center"}>
                  Mijn laatst geopende dossiers
                </h2>
                <div className={`${classes.icon} ml-auto`}>
                  <>
                    {!showDossiers && <Icon name="chevron" alt="arrow-down" />}
                    {showDossiers && (
                      <div className={classes.chevron_up}>
                        <Icon name="chevron" alt="arrow-up" />
                      </div>
                    )}
                  </>
                </div>
                <div
                  className={classes.dossiers}
                  onClick={
                    /* istanbul ignore next */ (e) => e.stopPropagation()
                  }
                >
                  <LaatsteDossiers showDossiers={showDossiers} />
                </div>
              </button>
            </div>
            <Debug />
          </div>
        </ZoekschermBody>
        {/* istanbul ignore next */ showMeldingmaken && user && (
          <SupportTicketModalAjax
            adviesdossierId={params.adviesdossier ?? null}
            user={user}
            closeModal={() => setShowMeldingMaken(false)}
            showInvalidResultErrorPage={() =>
              setShowInvalidAdviesboxResultErrorPage(true)
            }
          />
        )}
        {/* istanbul ignore next */ showRemoteAssistance && (
          <SupportRemoteAssistanceModal
            closeModal={() => setShowRemoteAssistance(false)}
          />
        )}
      </Form>
    </FormFirstFocus>
  );
};

Zoekscherm.displayName = "Zoekscherm";
export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema,
})(Zoekscherm);
